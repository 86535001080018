export const cities = [
  { label: "İstanbul", value: "Istanbul" },
  { label: "Ankara", value: "Ankara" },
  { label: "İzmir", value: "Izmir" },
  { label: "Amasya", value: "Amasya" },
  { label: "Bursa", value: "Burdur" },
  { label: "Yalova", value: "Yalova" },
  { label: "Bolu", value: "Bolu" },
  { label: "Manisa", value: "Manisa" },
  { label: "Antalya", value: "Antalya" },
  { label: "Adana", value: "Adana" },
  { label: "Trabzon", value: "Trabzon" },
  { label: "Rize", value: "Rize" },
  { label: "Ordu", value: "Ordu" },
  { label: "Edirne", value: "Edirne" },
];
export const BASE_URL = `https://quince.mervfar.codes`;

// export const cities = [
//   { value: "İstanbul" },
//   { value: "Ankara" },
//   { value: "İzmir" },
//   { value: "Amasya" },
//   { value: "Bursa" },
//   { value: "Kocaeli" },
//   { value: "Yalova" },
//   { value: "Bolu" },
//   { value: "Manisa" },
//   { value: "Antalya" },
//   { value: "Adana" },

// brand: "",
//     series: "",
//     price: "",
//     fuel: "",
//     carBody: "",
//     office: "AVIS Maslak",
//     gear: "",
//     imageUrl: "",
// ];
export const mockCarDetails = [
  {
    brand: "Ford",
    series: "Fiesta",
    carBody: "1.0 Ecoboost",
    fuel: "Benzin",
    gear: "PowerShift",
    office: "AVIS Maslak",
    price: "98.50",
    imageUrl: "https://source.unsplash.com/400x200/?car",
  },
  {
    brand: "Ford",
    series: "Focus",
    carBody: "1.0 Ecoboost",
    fuel: "Benzin",
    gear: "PowerShift",
    office: "Budget Ataşehir",
    price: "139.50",
    imageUrl: "https://source.unsplash.com/400x201/?car",
  },
  {
    brand: "Honda",
    series: "Civic",
    carBody: "1.6 CVT",
    fuel: "Dizel",
    gear: "Manuel",
    office: "Enterprise Ayazağa",
    price: "150",
    imageUrl: "https://source.unsplash.com/401x200/?car",
  },
  {
    brand: "Renault",
    series: "Clio",
    carBody: "0.9",
    fuel: "Benzin",
    gear: "Manuel",
    office: "AVIS Fikirtepe",
    price: "80",
    imageUrl: "https://source.unsplash.com/402x200/?car",
  },
];
